export const formatDate = (
  inputDate: string,
  includeYearIfNotCurrent?: boolean,
): string => {
  const date = new Date(inputDate)
  const currentYear = new Date().getUTCFullYear()
  const inputYear = date.getUTCFullYear()

  let options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }

  if (includeYearIfNotCurrent && inputYear !== currentYear) {
    options.year = 'numeric' // Include the year in 'yy' format
  }

  const formatter = new Intl.DateTimeFormat('en-US', options)
  return formatter.format(date)
}

export const formatDateTime = (inputDate: string): string => {
  const date = new Date(inputDate)
  const options = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  } as any

  const formatter = new Intl.DateTimeFormat('en-US', options)

  const parts = formatter.formatToParts(date)
  const month = parts.find(part => part.type === 'month')?.value
  const day = parts.find(part => part.type === 'day')?.value
  const hour = parts.find(part => part.type === 'hour')?.value
  const minute = parts.find(part => part.type === 'minute')?.value
  const dayPeriod = parts
    .find(part => part.type === 'dayPeriod')
    ?.value?.toLowerCase()

  return `${month} ${day} at ${hour}:${minute} ${dayPeriod}`
}

export const timeAgo = (timestamp: any) => {
  const currentTime = new Date() as any
  const pastTime = new Date(timestamp) as any
  const timeDifference = currentTime - pastTime

  const minutesAgo = Math.floor(timeDifference / (1000 * 60))
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60))
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  if (daysAgo >= 1) {
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`
  } else if (hoursAgo >= 1 && hoursAgo < 24) {
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`
  } else if (minutesAgo >= 1 && minutesAgo < 60) {
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`
  } else {
    return 'Just now'
  }
}

export const formatAmount = (amount: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  })

  return formatter.format(amount)
}

export const formatNumber = (number: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: number % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  })

  return formatter.format(number)
}

export const getInitials = (name: string | null): string => {
  if (typeof name !== 'string' || !name) {
    return ''
  }

  return name
    .split(' ')
    .map(part => part[0]?.toUpperCase() ?? '')
    .join('')
}

export const readableAmountFormatter = (number: any) => {
  if (isNaN(number)) {
    return '$0'
  } else if (number >= 1e6) {
    return '$' + parseFloat((number / 1e6).toFixed(2)) + 'M'
  } else if (number >= 1e3) {
    return '$' + (number / 1e3).toFixed(0) + 'K'
  }
  return '$' + number
}

export const getCurrentDateFormatted = (): string => {
  const currentDate = new Date()

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/New_York',
  })

  const formattedDate = formatter.format(currentDate)

  const dateWithSuffix = formattedDate.replace(
    /(\d+)(st|nd|rd|th)?/,
    (match, day) => {
      let suffix = 'th'
      const dayInt = parseInt(day, 10)
      if (dayInt < 10 || dayInt > 20) {
        switch (dayInt % 10) {
          case 1:
            suffix = 'st'
            break
          case 2:
            suffix = 'nd'
            break
          case 3:
            suffix = 'rd'
            break
          default:
            break
        }
      }
      return `${day}${suffix}`
    },
  )

  return dateWithSuffix
}

export const demoAverageTimeline = [
  {
    date: 'April 01, 2024',
    asad: '31 days',
    daniel: '36 days',
    nick: '20 days',
    lebron: '25 days',
    michael: '21 days',
  },
  {
    date: 'March 26, 2024',
    asad: '29 days',
    daniel: '38 days',
    nick: '20 days',
    lebron: '24 days',
    michael: '19 days',
  },
  {
    date: 'March 19, 2024',
    asad: '28 days',
    daniel: '37 days',
    nick: '18 days',
    lebron: '23 days',
    michael: '21 days',
  },
  {
    date: 'March 12, 2024',
    asad: '29 days',
    daniel: '35 days',
    nick: '19 days',
    lebron: '21 days',
    michael: '21 days',
  },
  {
    date: 'March 05, 2024',
    asad: '30 days',
    daniel: '33 days',
    nick: '18 days',
    lebron: '22 days',
    michael: '22 days',
  },
  {
    date: 'February 27, 2024',
    asad: '31 days',
    daniel: '31 days',
    nick: '17 days',
    lebron: '21 days',
    michael: '21 days',
  },
  {
    date: 'February 20, 2024',
    asad: '29 days',
    daniel: '31 days',
    nick: '19 days',
    lebron: '22 days',
    michael: '22 days',
  },
  {
    date: 'February 13, 2024',
    asad: '29 days',
    daniel: '33 days',
    nick: '19 days',
    lebron: '20 days',
    michael: '20 days',
  },
  {
    date: 'February 06, 2024',
    asad: '31 days',
    daniel: '31 days',
    nick: '21 days',
    lebron: '19 days',
    michael: '22 days',
  },
  {
    date: 'January 30, 2024',
    asad: '30 days',
    daniel: '31 days',
    nick: '21 days',
    lebron: '17 days',
    michael: '21 days',
  },
  {
    date: 'January 23, 2024',
    asad: '29 days',
    daniel: '30 days',
    nick: '20 days',
    lebron: '18 days',
    michael: '22 days',
  },
  {
    date: 'January 16, 2024',
    asad: '29 days',
    daniel: '31 days',
    nick: '18 days',
    lebron: '19 days',
    michael: '24 days',
  },
  {
    date: 'January 09, 2024',
    asad: '28 days',
    daniel: '31 days',
    nick: '20 days',
    lebron: '20 days',
    michael: '26 days',
  },
  {
    date: 'January 02, 2024',
    asad: '30 days',
    daniel: '33 days',
    nick: '21 days',
    lebron: '22 days',
    michael: '28 days',
  },
]

export const demoDealTimeline = [
  {
    date: 'April 01, 2024',
    asad: '70',
    daniel: '60',
    nick: '50',
    lebron: '40',
    michael: '30',
  },
  {
    date: 'March 26, 2024',
    asad: '65',
    daniel: '55',
    nick: '45',
    lebron: '35',
    michael: '25',
  },
  {
    date: 'March 19, 2024',
    asad: '60',
    daniel: '50',
    nick: '40',
    lebron: '30',
    michael: '20',
  },
  {
    date: 'March 12, 2024',
    asad: '55',
    daniel: '45',
    nick: '35',
    lebron: '25',
    michael: '15',
  },
  {
    date: 'March 05, 2024',
    asad: '50',
    daniel: '40',
    nick: '30',
    lebron: '20',
    michael: '10',
  },
  {
    date: 'February 27, 2024',
    asad: '45',
    daniel: '35',
    nick: '25',
    lebron: '15',
    michael: '5',
  },
  {
    date: 'February 20, 2024',
    asad: '40',
    daniel: '30',
    nick: '20',
    lebron: '10',
    michael: '0',
  },
  {
    date: 'February 13, 2024',
    asad: '35',
    daniel: '25',
    nick: '15',
    lebron: '5',
    michael: '0',
  },
  {
    date: 'February 06, 2024',
    asad: '30',
    daniel: '20',
    nick: '10',
    lebron: '0',
    michael: '0',
  },
  {
    date: 'January 30, 2024',
    asad: '',
    daniel: '',
    nick: '',
    lebron: '',
    michael: '',
  },
  {
    date: 'January 23, 2024',
    asad: '',
    daniel: '',
    nick: '',
    lebron: '',
    michael: '',
  },
  {
    date: 'January 16, 2024',
    asad: '',
    daniel: '',
    nick: '',
    lebron: '',
    michael: '',
  },
  {
    date: 'January 09, 2024',
    asad: '',
    daniel: '',
    nick: '',
    lebron: '',
    michael: '',
  },
  {
    date: 'January 02, 2024',
    asad: '',
    daniel: '',
    nick: '',
    lebron: '',
    michael: '',
  },
]

export const demoClosedDeals = [
  {
    Id: '006Dn000004ZKJSIA4',
    IsDeleted: false,
    OwnerName: 'Asad Dar',
    AccountId: '001Dn000008pFeiIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Edge Installation',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 50500,
    Probability: 100,
    ExpectedRevenue: 50500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-19',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Phone Inquiry',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T04:54:48.000+0000',
    LastReferencedDate: '2024-01-30T04:54:48.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqpIAE',
    LastCloseDateChangedHistoryId: '008Dn0000147PKTIA2',
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: null,
    OrderNumber__c: '835178',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 10000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJTIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFeiIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Edge SLA',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 60500,
    OwnerName: 'Daniel Ruiz',
    Probability: 100,
    ExpectedRevenue: 60500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-02',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Web',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T02:03:18.000+0000',
    LastReferencedDate: '2024-01-30T02:03:18.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqqIAE',
    LastCloseDateChangedHistoryId: '008Dn0000147PHBIA2',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: '847564',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 10000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJUIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Installations',
    Description: 'TEST',
    StageName: 'Closed Won',
    OwnerName: 'Asad Dar',
    Amount: 270500,
    Probability: 100,
    ExpectedRevenue: 270500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-09-29',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Partner',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2022,
    Fiscal: '2022 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqrIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '427609',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJVIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels Generator Installations',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 350500,
    Probability: 100,
    ExpectedRevenue: 350500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-16',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    OwnerName: 'Daniel Ruiz',
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqsIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: null,
    OrderNumber__c: '763546',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJWIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Refinery Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    OwnerName: 'Asad Dar',
    Amount: 915500,
    Probability: 100,
    ExpectedRevenue: 915500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-11',
    Type: 'New Customer',
    NextStep: null,
    LeadSource: 'Partner',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqtIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '744343',
    CurrentGenerators__c: 'John Deere',
    MainCompetitors__c: 'John Deere, Mitsubishi, Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJXIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFepIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'University of AZ Installations',
    Description: 'TEST',
    OwnerName: 'Daniel Ruiz',
    StageName: 'Closed Won',
    Amount: 100500,
    Probability: 100,
    ExpectedRevenue: 100500,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-06-22',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Employee Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2023,
    Fiscal: '2023 2',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IquIAE',
    LastCloseDateChangedHistoryId: '008Dn00000xvmKNIAY',
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 39000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJZIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFepIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'University of AZ SLA',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 90500,
    OwnerName: 'Daniel Ruiz',
    Probability: 100,
    ExpectedRevenue: 90500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-09-07',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Public Relations',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2022,
    Fiscal: '2022 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqwIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '654985',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 39000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJbIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    OwnerName: 'Asad Dar',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Installations',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 235500,
    Probability: 100,
    ExpectedRevenue: 235500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-10-11',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqyIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '554821',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJcIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Emergency Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 445500,
    OwnerName: 'Daniel Ruiz',
    Probability: 66,
    ExpectedRevenue: 294030,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-21',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T02:00:51.000+0000',
    LastReferencedDate: '2024-01-30T02:00:51.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eMNIAY',
    LastCloseDateChangedHistoryId: '008Dn0000147PHPIA2',
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301420',
    OrderNumber__c: '847324',
    CurrentGenerators__c: 'Fujitsu',
    MainCompetitors__c: 'Honda, Mitsubishi',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJdIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels SLA',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 100000,
    Probability: 100,
    ExpectedRevenue: 100000,
    OwnerName: 'Asad Dar',
    TotalOpportunityQuantity: null,
    CloseDate: '2022-08-27',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2022,
    Fiscal: '2022 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eOXIAY',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '254676',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJeIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels Emergency Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 210500,
    OwnerName: 'Daniel Ruiz',
    Probability: 100,
    ExpectedRevenue: 210500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-14',
    Type: 'New Customer',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145Ir1IAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '387624',
    CurrentGenerators__c: 'Fujitsu',
    MainCompetitors__c: 'Caterpillar',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJhIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Standby Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 122500,
    Probability: 100,
    OwnerName: 'Asad Dar',
    ExpectedRevenue: 122500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-26',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eONIAY',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '525476',
    CurrentGenerators__c: 'John Deere',
    MainCompetitors__c: 'John Deere, Mitsubishi, Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJiIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFesIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'GenePoint Lab Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 615002,
    Probability: 100,
    ExpectedRevenue: 615002,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-05',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-27T19:40:58.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-27T19:40:58.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    OwnerName: 'Daniel Ruiz',
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T04:55:38.000+0000',
    LastReferencedDate: '2024-01-30T04:55:38.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn000016iO6DIAU',
    LastCloseDateChangedHistoryId: '008Dn0000147PKNIA2',
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'Hawkpower, Fujitsu',
    MainCompetitors__c: 'Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 265,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJjIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFesIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'GenePoint SLA',
    Description: 'TEST',
    OwnerName: 'Asad Dar',
    StageName: 'Closed Won',
    Amount: 1440500,
    Probability: 100,
    ExpectedRevenue: 1440500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-24',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Partner',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-02-09T19:10:57.000+0000',
    LastReferencedDate: '2024-02-09T19:10:57.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147hOBIAY',
    LastCloseDateChangedHistoryId: '008Dn0000147PKQIA2',
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '546512',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 265,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000CDF2QIAX',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'StarNest Designs',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 200500,
    OwnerName: 'Daniel Ruiz',
    Probability: 100,
    ExpectedRevenue: 200500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-29',
    Type: null,
    NextStep: null,
    LeadSource: 'Web',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2023-09-04T01:47:23.000+0000',
    CreatedById: '005Dn0000072NyuIAE',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-02-21T23:28:14.000+0000',
    LastReferencedDate: '2024-02-21T23:28:14.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145Ir8IAE',
    LastCloseDateChangedHistoryId: '008Dn0000147YFIIA2',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ej51QIAR',
    IsDeleted: false,
    AccountId: '001Dn000014VuirIAC',
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Sunlight renewal',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 17573,
    OwnerName: 'Asad Dar',
    Probability: 100,
    ExpectedRevenue: 17573,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-30',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2024-03-26T19:47:58.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-26T19:47:59.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-26T19:47:59.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: null,
    LastCloseDateChangedHistoryId: '008Dn000016iNfBIAU',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ej51RIAR',
    IsDeleted: false,
    AccountId: '001Dn000014VuidIAC',
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    OwnerName: 'Daniel Ruiz',
    Name: 'Acepolly second use case',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 18049,
    Probability: 100,
    ExpectedRevenue: 18049,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-30',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2024-03-26T19:47:58.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-26T19:47:59.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-26T19:47:59.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: null,
    LastCloseDateChangedHistoryId: '008Dn000016iNfCIAU',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ej51SIAR',
    IsDeleted: false,
    AccountId: '001Dn000014VuikIAC',
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Huyler inquiry',
    Description: 'TEST',
    OwnerName: 'Asad Dar',
    StageName: 'Closed Won',
    Amount: 15161,
    Probability: 100,
    ExpectedRevenue: 15161,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-30',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2024-03-26T19:47:58.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-26T19:47:59.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-26T19:47:59.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: null,
    LastCloseDateChangedHistoryId: '008Dn000016iNfDIAU',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ej51TIAR',
    IsDeleted: false,
    AccountId: '001Dn000014VuilIAC',
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'JJ second team',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 20068,
    Probability: 100,
    ExpectedRevenue: 20068,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-30',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2024-03-26T19:47:58.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-26T19:47:59.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-26T19:47:59.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: null,
    LastCloseDateChangedHistoryId: '008Dn000016iNfEIAU',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ej51UIAR',
    IsDeleted: false,
    AccountId: '001Dn000013HogUIAS',
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Owlimited expansion',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 21304,
    Probability: 100,
    ExpectedRevenue: 21304,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-30',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2024-03-26T19:47:58.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-26T19:47:59.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-26T19:47:59.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: null,
    LastCloseDateChangedHistoryId: '008Dn000016iNfFIAU',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
]

export const demoDeals = [
  {
    Id: '006Dn000003joxLIAQ',
    IsDeleted: false,
    AccountId: '001Dn000008J6KsIAK',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Edge Communications',
    Description: 'TEST\n\nAAA',
    StageName: 'Id. Decision Makers',
    Amount: 300000,
    OwnerName: 'Asad Dar',
    Probability: 60,
    ExpectedRevenue: 1.7999999999999998,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-06-13',
    Type: 'New Customer',
    NextStep: 'hello',
    LeadSource: 'Partner Referral',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-15T01:23:33.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-04-02T15:05:31.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-04-02T15:05:31.000+0000',
    LastActivityDate: '2024-01-30',
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: '003Dn000004lp8VIAQ',
    LastViewedDate: '2024-03-18T05:24:03.000+0000',
    LastReferencedDate: '2024-03-18T05:24:03.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn000016iPCeIAM',
    LastCloseDateChangedHistoryId: '008Dn000016iQRxIAM',
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: '001Dn000008pFejIAE',
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: true,
    AccountRating__c: null,
    Account_Employees__c: 54,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: '123',
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJFIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFelIAE',
    RecordTypeId: null,
    IsPrivate: false,
    OwnerName: 'Daniel Ruiz',
    Name: 'United Oil & Gas Corp.',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 2000004,
    Probability: 90,
    ExpectedRevenue: 1800003.6,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-11-06',
    Type: 'New Customer',
    NextStep:
      'really long next step really long next step really long next step really long next step really long next step really long next step really long next step',
    LeadSource: 'Phone Inquiry',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-29T18:45:18.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-29T18:45:18.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2023,
    Fiscal: '2023 4',
    ContactId: null,
    LastViewedDate: '2024-03-18T05:24:05.000+0000',
    LastReferencedDate: '2024-03-18T05:24:05.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147ckJIAQ',
    LastCloseDateChangedHistoryId: '008Dn0000147OZkIAM',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: 'Honda',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 120,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJGIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    OwnerName: 'Asad Dar',
    Name: 'United Oil SLA',
    Description: 'TEST',
    StageName: 'Closed Lost',
    Amount: 500,
    Probability: 0,
    ExpectedRevenue: 0,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-25',
    Type: 'Existing Customer - Upgrade',
    NextStep: 'Contact champion about proposal',
    LeadSource: 'Purchased List',
    IsClosed: true,
    IsWon: false,
    ForecastCategory: 'Omitted',
    ForecastCategoryName: 'Omitted',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-04-02T15:59:21.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-04-02T15:59:21.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-03-18T05:24:07.000+0000',
    LastReferencedDate: '2024-03-18T05:24:07.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147cDcIAI',
    LastCloseDateChangedHistoryId: '008Dn0000147YIRIA2',
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '926363',
    CurrentGenerators__c: 'Honda',
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJHIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    OwnerName: 'Daniel Ruiz',
    Name: 'United Oil Office Portable Generators',
    Description: 'TEST',
    StageName: 'Proposal/Price Quote',
    Amount: 14003,
    Probability: 75,
    ExpectedRevenue: 10502.25,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-10-31',
    Type: 'Existing Customer - Upgrade',
    NextStep: 'asd',
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'BestCase',
    ForecastCategoryName: 'Best Case',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: '2024-03-18T05:24:10.000+0000',
    LastReferencedDate: '2024-03-18T05:24:10.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147clMIAQ',
    LastCloseDateChangedHistoryId: '008Dn0000147UJbIAM',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'Yamaha 2',
    MainCompetitors__c: 'Yamaha',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJIIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFesIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'GenePoint Standby Generator',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 2500442,
    Probability: 90,
    ExpectedRevenue: 2250397.8000000003,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-01-31',
    Type: 'New Customer',
    OwnerName: 'Asad Dar',
    NextStep: null,
    LeadSource: 'Phone Inquiry',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-03-18T05:24:13.000+0000',
    LastReferencedDate: '2024-03-18T05:24:13.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147fi9IAA',
    LastCloseDateChangedHistoryId: '008Dn0000147Q2uIAE',
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301420',
    OrderNumber__c: '908676',
    CurrentGenerators__c: 'Honda',
    MainCompetitors__c: 'Honda',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 265,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJJIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Refinery Generators',
    Description: 'TEST',
    StageName: 'Perception Analysis',
    Amount: 2050451123,
    Probability: 70,
    OwnerName: 'Daniel Ruiz',
    ExpectedRevenue: 1435315786.1,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-18',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: '2024-03-18T05:24:15.000+0000',
    LastReferencedDate: '2024-03-18T05:24:15.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147csWIAQ',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'Caterpillar',
    MainCompetitors__c: 'John Deere',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJKIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFeoIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'GenePoint',
    Description: 'TEST',
    StageName: 'Proposal/Price Quote',
    Amount: 35000,
    Probability: 75,
    ExpectedRevenue: 26250,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-08-30',
    Type: 'New Customer',
    NextStep: null,
    LeadSource: 'Trade Show',
    IsClosed: false,
    OwnerName: 'Asad Dar',
    IsWon: false,
    ForecastCategory: 'BestCase',
    ForecastCategoryName: 'Best Case',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2023,
    Fiscal: '2023 3',
    ContactId: null,
    LastViewedDate: '2024-03-18T05:24:19.000+0000',
    LastReferencedDate: '2024-03-18T05:24:19.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147bifIAA',
    LastCloseDateChangedHistoryId: '008Dn0000147ORrIAM',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: '830150301420',
    OrderNumber__c: '653276',
    CurrentGenerators__c: 'Hawkpower',
    MainCompetitors__c: 'Fujitsu',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1230,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJLIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels Kitchen Generator',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 15503123,
    Probability: 90,
    ExpectedRevenue: 13952810.700000001,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-07-19',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Phone Inquiry',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerName: 'Daniel Ruiz',
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-04-01T20:56:10.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-04-01T20:56:10.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2023,
    Fiscal: '2023 3',
    ContactId: null,
    LastViewedDate: '2024-03-20T22:41:08.000+0000',
    LastReferencedDate: '2024-03-20T22:41:08.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147cs2IAA',
    LastCloseDateChangedHistoryId: '008Dn0000147cs7IAA',
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'John Deere',
    MainCompetitors__c: 'Fujitsu, Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJMIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels Guest Portable Generators',
    Description: 'TEST',
    StageName: 'Perception Analysis',
    Amount: 250502,
    Probability: 70,
    ExpectedRevenue: 175351.4,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-12-03',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Employee Referral',
    IsClosed: false,
    IsWon: false,
    OwnerName: 'Asad Dar',
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: '2024-03-01T20:53:16.000+0000',
    LastReferencedDate: '2024-03-01T20:53:16.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147bkIIAQ',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'Honda',
    MainCompetitors__c: 'Honda',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJNIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFeiIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Edge Emergency Generator',
    Description: 'TEST',
    StageName: 'Perception Analysis',
    Amount: 755012,
    Probability: 70,
    ExpectedRevenue: 528508.4,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-06-12',
    Type: 'New Customer',
    OwnerName: 'Daniel Ruiz',
    NextStep: null,
    LeadSource: 'Partner Referral',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-04-02T15:55:23.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-04-02T15:55:23.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-02-20T21:21:46.000+0000',
    LastReferencedDate: '2024-02-20T21:21:46.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147SboIAE',
    LastCloseDateChangedHistoryId: '008Dn000016iQRdIAM',
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301420',
    OrderNumber__c: '731645',
    CurrentGenerators__c: 'John Deere',
    MainCompetitors__c: 'John Deere',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: '2024-01-31T19:15:00.000+0000',
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 10000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJOIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFepIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'University of AZ Portable Generators',
    Description: 'TEST',
    StageName: 'Needs Analysis',
    Amount: 51000,
    Probability: 20,
    ExpectedRevenue: 10200,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-06-19',
    Type: 'New Customer',
    OwnerName: 'Asad Dar',
    NextStep: null,
    LeadSource: 'Public Relations',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-04-02T15:08:18.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-04-02T15:08:18.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-03-01T20:57:03.000+0000',
    LastReferencedDate: '2024-03-01T20:57:03.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147bipIAA',
    LastCloseDateChangedHistoryId: '008Dn000016iQSWIA2',
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '768934',
    CurrentGenerators__c: 'Mitsubishi',
    MainCompetitors__c: 'Honda, Mitsubishi',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 39000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJPIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFekIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Pyramid Emergency Generators',
    Description: 'TEST',
    StageName: 'Closed Lost',
    Amount: 1200,
    Probability: 0,
    ExpectedRevenue: 0,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-01-30',
    OwnerName: 'Daniel Ruiz',
    Type: null,
    NextStep: null,
    LeadSource: 'Web',
    IsClosed: true,
    IsWon: false,
    ForecastCategory: 'Omitted',
    ForecastCategoryName: 'Omitted',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-03-01T20:53:33.000+0000',
    LastReferencedDate: '2024-03-01T20:53:33.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147az5IAA',
    LastCloseDateChangedHistoryId: '008Dn0000147PcdIAE',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 2680,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJQIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFeoIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Express Logistics Portable Truck Generators',
    Description: 'TEST',
    StageName: 'Prospecting',
    Amount: 750000,
    Probability: 10,
    OwnerName: 'Asad Dar',
    ExpectedRevenue: 75000,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-20',
    Type: 'Existing Customer - Upgrade',
    NextStep: 'Schedule time for final demo',
    LeadSource: 'External Referral',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-03-10T05:33:19.000+0000',
    LastReferencedDate: '2024-03-10T05:33:19.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147epTIAQ',
    LastCloseDateChangedHistoryId: '008Dn0000147epYIAQ',
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'Fujitsu',
    MainCompetitors__c: 'Honda',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1230,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJRIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Installations',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 270501,
    Probability: 90,
    ExpectedRevenue: 243450.9,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-09-30',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    OwnerName: 'Daniel Ruiz',
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2022,
    Fiscal: '2022 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147KfcIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJSIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFeiIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Edge Installation',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 50500,
    Probability: 100,
    ExpectedRevenue: 50500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-19',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Phone Inquiry',
    IsClosed: true,
    OwnerName: 'Asad Dar',
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T04:54:48.000+0000',
    LastReferencedDate: '2024-01-30T04:54:48.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqpIAE',
    LastCloseDateChangedHistoryId: '008Dn0000147PKTIA2',
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: null,
    OrderNumber__c: '835178',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 10000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJTIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFeiIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Edge SLA',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 60500,
    Probability: 100,
    ExpectedRevenue: 60500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-02',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    OwnerName: 'Daniel Ruiz',
    LeadSource: 'Web',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T02:03:18.000+0000',
    LastReferencedDate: '2024-01-30T02:03:18.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqqIAE',
    LastCloseDateChangedHistoryId: '008Dn0000147PHBIA2',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: '847564',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 10000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJUIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Installations',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 270500,
    Probability: 100,
    ExpectedRevenue: 270500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-09-29',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Partner',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2022,
    Fiscal: '2022 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqrIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '427609',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJVIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels Generator Installations',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 350500,
    Probability: 100,
    ExpectedRevenue: 350500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-16',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqsIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: null,
    OrderNumber__c: '763546',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJWIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Refinery Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 915500,
    Probability: 100,
    ExpectedRevenue: 915500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-11',
    Type: 'New Customer',
    NextStep: null,
    LeadSource: 'Partner',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqtIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '744343',
    CurrentGenerators__c: 'John Deere',
    MainCompetitors__c: 'John Deere, Mitsubishi, Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJXIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFepIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'University of AZ Installations',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 100500,
    Probability: 100,
    ExpectedRevenue: 100500,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-06-22',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Employee Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2023,
    Fiscal: '2023 2',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IquIAE',
    LastCloseDateChangedHistoryId: '008Dn00000xvmKNIAY',
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 39000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJYIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFeoIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Express Logistics SLA',
    Description: 'TEST',
    StageName: 'Perception Analysis',
    Amount: 120800,
    Probability: 70,
    ExpectedRevenue: 84560,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-17',
    Type: 'Existing Customer - Upgrade',
    NextStep: 'schedule time for demo',
    LeadSource: 'External Referral',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-03-13T18:48:16.000+0000',
    LastReferencedDate: '2024-03-13T18:48:16.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147fnYIAQ',
    LastCloseDateChangedHistoryId: '008Dn0000147fndIAA',
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1230,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJZIA4',
    IsDeleted: false,
    AccountId: '001Dn000008pFepIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'University of AZ SLA',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 90500,
    Probability: 100,
    ExpectedRevenue: 90500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-09-07',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Public Relations',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2022,
    Fiscal: '2022 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqwIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '654985',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 39000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJaIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFejIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Burlington Textiles Weaving Plant Generator',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 500000,
    Probability: 90,
    ExpectedRevenue: 450000,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-17',
    Type: 'New Customer',
    NextStep: 'Demo requested features that were asked for on last call',
    LeadSource: 'Web',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: '2023-12-20',
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-02-23T16:50:32.000+0000',
    LastReferencedDate: '2024-02-23T16:50:32.000+0000',
    HasOpenActivity: true,
    HasOverdueTask: true,
    LastAmountChangedHistoryId: '008Dn0000147YutIAE',
    LastCloseDateChangedHistoryId: '008Dn0000147YuyIAE',
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '645612',
    CurrentGenerators__c: 'John Deere',
    MainCompetitors__c: 'John Deere',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: '001Dn00000PojB8IAJ',
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 9000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJbIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Installations',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 235500,
    Probability: 100,
    ExpectedRevenue: 235500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-10-11',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145IqyIAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '554821',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJcIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Emergency Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 445500,
    Probability: 66,
    ExpectedRevenue: 294030,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-21',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T02:00:51.000+0000',
    LastReferencedDate: '2024-01-30T02:00:51.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eMNIAY',
    LastCloseDateChangedHistoryId: '008Dn0000147PHPIA2',
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301420',
    OrderNumber__c: '847324',
    CurrentGenerators__c: 'Fujitsu',
    MainCompetitors__c: 'Honda, Mitsubishi',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJdIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels SLA',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 100000,
    Probability: 100,
    ExpectedRevenue: 100000,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-08-27',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2022,
    Fiscal: '2022 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eOXIAY',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '254676',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJeIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFemIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Grand Hotels Emergency Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 210500,
    Probability: 100,
    ExpectedRevenue: 210500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-14',
    Type: 'New Customer',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145Ir1IAE',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: 'Completed',
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '387624',
    CurrentGenerators__c: 'Fujitsu',
    MainCompetitors__c: 'Caterpillar',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: true,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 5600,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJfIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Plant Standby Generators',
    Description: 'TEST',
    StageName: 'Needs Analysis',
    Amount: 1475500,
    Probability: 20,
    ExpectedRevenue: 295100,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-10-28',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147bWoIAI',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'Caterpillar',
    MainCompetitors__c: 'Caterpillar, Mitsubishi, Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJgIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFeiIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'Edge Emergency Generator',
    Description: 'TEST',
    StageName: 'Id. Decision Makers',
    Amount: 35500,
    Probability: 60,
    ExpectedRevenue: 21300,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-25',
    Type: 'Existing Customer - Replacement',
    NextStep: null,
    LeadSource: 'Purchased List',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-02-21T23:43:31.000+0000',
    LastReferencedDate: '2024-02-21T23:43:31.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145Ir3IAE',
    LastCloseDateChangedHistoryId: '008Dn0000147YGBIA2',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: 'John Deere, Mitsubishi, Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 10000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJhIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'United Oil Standby Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 122500,
    Probability: 100,
    ExpectedRevenue: 122500,
    TotalOpportunityQuantity: null,
    CloseDate: '2022-11-26',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'External Referral',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 4,
    FiscalYear: 2022,
    Fiscal: '2022 4',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eONIAY',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: '830150301360',
    OrderNumber__c: '525476',
    CurrentGenerators__c: 'John Deere',
    MainCompetitors__c: 'John Deere, Mitsubishi, Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJiIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFesIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'GenePoint Lab Generators',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 615002,
    Probability: 100,
    ExpectedRevenue: 615002,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-05',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-27T19:40:58.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-27T19:40:58.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T04:55:38.000+0000',
    LastReferencedDate: '2024-01-30T04:55:38.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn000016iO6DIAU',
    LastCloseDateChangedHistoryId: '008Dn0000147PKNIA2',
    DeliveryInstallationStatus__c: 'Yet to begin',
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: 'Hawkpower, Fujitsu',
    MainCompetitors__c: 'Hawkpower',
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 265,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn000004ZKJjIAO',
    IsDeleted: false,
    AccountId: '001Dn000008pFesIAE',
    RecordTypeId: null,
    IsPrivate: false,
    Name: 'GenePoint SLA',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 1440500,
    Probability: 100,
    ExpectedRevenue: 1440500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-24',
    Type: 'Existing Customer - Upgrade',
    NextStep: null,
    LeadSource: 'Partner',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2022-12-07T16:52:31.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-02-09T19:10:57.000+0000',
    LastReferencedDate: '2024-02-09T19:10:57.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147hOBIAY',
    LastCloseDateChangedHistoryId: '008Dn0000147PKQIA2',
    DeliveryInstallationStatus__c: 'In progress',
    TrackingNumber__c: null,
    OrderNumber__c: '546512',
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 265,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ayc7PIAR',
    IsDeleted: false,
    AccountId: '001Dn000008J6KsIAK',
    RecordTypeId: '012Dn0000008EAbIAM',
    IsPrivate: false,
    Name: 'Brooklyn Nets',
    Description: 'TEST',
    StageName: 'Id. Decision Makers',
    Amount: 5000000,
    Probability: 60,
    ExpectedRevenue: 3000000,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-08-15',
    Type: null,
    NextStep: null,
    LeadSource: 'Other',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2023-07-08T05:59:21.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2023,
    Fiscal: '2023 3',
    ContactId: null,
    LastViewedDate: '2024-01-17T07:16:02.000+0000',
    LastReferencedDate: '2024-01-17T07:16:02.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147JcHIAU',
    LastCloseDateChangedHistoryId: '008Dn000010FxX1IAK',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 54,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000AycZ7IAJ',
    IsDeleted: false,
    AccountId: '001Dn000008J6KsIAK',
    RecordTypeId: '012Dn0000008EAbIAM',
    IsPrivate: false,
    Name: 'Los Angeles Lakers',
    Description: 'TEST',
    StageName: 'Prospecting',
    Amount: 100000,
    Probability: 10,
    ExpectedRevenue: 10000,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-08-15',
    Type: null,
    NextStep: null,
    LeadSource: 'Web',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2023-07-09T04:17:50.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2023,
    Fiscal: '2023 3',
    ContactId: null,
    LastViewedDate: '2024-01-17T07:16:06.000+0000',
    LastReferencedDate: '2024-01-17T07:16:06.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn000016iN7wIAE',
    LastCloseDateChangedHistoryId: '008Dn000010FxX2IAK',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 54,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: '2024-03-13',
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ayg7jIAB',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: '012Dn0000008EAbIAM',
    IsPrivate: false,
    Name: 'New Facility',
    Description: 'TEST',
    StageName: 'Prospecting',
    Amount: 3453992000142,
    Probability: 10,
    ExpectedRevenue: 345399200014.2,
    TotalOpportunityQuantity: null,
    CloseDate: '2000-07-01',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2023-07-11T18:16:15.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2000,
    Fiscal: '2000 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147Pe2IAE',
    LastCloseDateChangedHistoryId: '008Dn0000147KGnIAM',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ayg88IAB',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: '012Dn0000008EAbIAM',
    IsPrivate: false,
    Name: 'NEWWWWWW',
    Description: 'TEST',
    StageName: 'Prospecting',
    Amount: 555555555,
    Probability: 10,
    ExpectedRevenue: 55555555.5,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-08-02',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2023-07-11T18:17:37.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:45.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:45.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2023,
    Fiscal: '2023 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147NUKIA2',
    LastCloseDateChangedHistoryId: '008Dn0000147QrHIAU',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ayg8IIAR',
    IsDeleted: false,
    AccountId: '001Dn000008pFenIAE',
    RecordTypeId: '012Dn0000008EAbIAM',
    IsPrivate: false,
    Name: 'Phoenix Suns',
    Description: 'TEST',
    StageName: 'Prospecting',
    Amount: 2552133,
    Probability: 10,
    ExpectedRevenue: 255213.30000000002,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-07-18',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2023-07-11T18:17:42.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:45.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:45.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2024,
    Fiscal: '2024 3',
    ContactId: null,
    LastViewedDate: '2024-02-22T03:10:57.000+0000',
    LastReferencedDate: '2024-02-22T03:10:57.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147RQZIA2',
    LastCloseDateChangedHistoryId: '008Dn0000147YI7IAM',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 1450,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000Ayg8NIAR',
    IsDeleted: false,
    AccountId: '001Dn000008pFepIAE',
    RecordTypeId: '012Dn0000008EAbIAM',
    IsPrivate: false,
    Name: 'NEWWWWWW',
    Description: 'TEST',
    StageName: 'Prospecting',
    Amount: 34523222,
    Probability: 10,
    ExpectedRevenue: 3452322.2,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-08-15',
    Type: null,
    NextStep: null,
    LeadSource: null,
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2023-07-11T18:18:05.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2023,
    Fiscal: '2023 3',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147MsgIAE',
    LastCloseDateChangedHistoryId: '008Dn000010FxX5IAK',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 39000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000CDF2GIAX',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'QuantumLeaf Technologies',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 100500,
    Probability: 90,
    ExpectedRevenue: 90450,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-08-22',
    Type: null,
    NextStep: null,
    LeadSource: 'Phone Inquiry',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2023-09-04T01:43:05.000+0000',
    CreatedById: '005Dn0000072NyuIAE',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 3,
    FiscalYear: 2024,
    Fiscal: '2024 3',
    ContactId: null,
    LastViewedDate: '2024-03-07T01:16:48.000+0000',
    LastReferencedDate: '2024-03-07T01:16:48.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145Ir7IAE',
    LastCloseDateChangedHistoryId: '008Dn0000147YIMIA2',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000CDF2QIAX',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'StarNest Designs',
    Description: 'TEST',
    StageName: 'Closed Won',
    Amount: 200500,
    Probability: 100,
    ExpectedRevenue: 200500,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-29',
    Type: null,
    NextStep: null,
    LeadSource: 'Web',
    IsClosed: true,
    IsWon: true,
    ForecastCategory: 'Closed',
    ForecastCategoryName: 'Closed',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2023-09-04T01:47:23.000+0000',
    CreatedById: '005Dn0000072NyuIAE',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-02-21T23:28:14.000+0000',
    LastReferencedDate: '2024-02-21T23:28:14.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000145Ir8IAE',
    LastCloseDateChangedHistoryId: '008Dn0000147YFIIA2',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: true,
    Marketing_Meeting_Completed__c: true,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000CDF2kIAH',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Vortex Dynamics Inc.',
    Description: 'TEST',
    StageName: 'Proposal/Price Quote',
    Amount: 100705,
    Probability: 75,
    ExpectedRevenue: 75528.75,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-16',
    Type: null,
    NextStep: 'Set up time for a demo',
    LeadSource: 'Purchased List',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'BestCase',
    ForecastCategoryName: 'Best Case',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2023-09-04T01:49:28.000+0000',
    CreatedById: '005Dn0000072NyuIAE',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-02-23T16:56:38.000+0000',
    LastReferencedDate: '2024-02-23T16:56:38.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147YvNIAU',
    LastCloseDateChangedHistoryId: '008Dn0000147YvSIAU',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000CDF2lIAH',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'AeroMingle Aviation',
    Description: 'TEST',
    StageName: 'Closed Lost',
    Amount: 900000,
    Probability: 0,
    ExpectedRevenue: 0,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-22',
    Type: null,
    NextStep: null,
    LeadSource: 'Other',
    IsClosed: true,
    IsWon: false,
    ForecastCategory: 'Omitted',
    ForecastCategoryName: 'Omitted',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2023-09-04T02:01:36.000+0000',
    CreatedById: '005Dn0000072NyuIAE',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-01-30T01:57:06.000+0000',
    LastReferencedDate: '2024-01-30T01:57:06.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eMWIAY',
    LastCloseDateChangedHistoryId: '008Dn0000147PH5IAM',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQ877IAD',
    IsDeleted: false,
    AccountId: '001Dn00000o196nIAA',
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Miami Heat',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 3800000,
    Probability: 90,
    ExpectedRevenue: 3420000,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-17',
    Type: null,
    NextStep: 'hello now',
    LeadSource: 'Web',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2024-01-23T21:57:21.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:42.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:42.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-03-13T18:49:35.000+0000',
    LastReferencedDate: '2024-03-13T18:49:35.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147fo2IAA',
    LastCloseDateChangedHistoryId: '008Dn0000147fo7IAA',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: 10000,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQ8GBIA1',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Taco Bell',
    Description: 'TEST',
    StageName: 'Prospecting',
    Amount: 500000,
    Probability: 10,
    ExpectedRevenue: 50000,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-01-09',
    Type: null,
    NextStep: 'hello',
    LeadSource: 'Purchased List',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2024-01-25T02:49:48.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:45.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:45.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-03-20T05:25:02.000+0000',
    LastReferencedDate: '2024-03-20T05:25:02.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147hQxIAI',
    LastCloseDateChangedHistoryId: '008Dn0000147hR2IAI',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQ8i7IAD',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'New Opp',
    Description: 'TEST',
    StageName: 'Qualification',
    Amount: 451000,
    Probability: 10,
    ExpectedRevenue: 45100,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-01-31',
    Type: null,
    NextStep: null,
    LeadSource: 'Purchased List',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2024-01-30T19:10:30.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147ePaIAI',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQA6KIAX',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Milwaukee Bucks',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 1111000,
    Probability: 90,
    ExpectedRevenue: 999900,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-03-28',
    Type: null,
    NextStep: 'yes',
    LeadSource: 'Purchased List',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2024-02-05T19:45:28.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-03-13T18:48:56.000+0000',
    LastReferencedDate: '2024-03-13T18:48:56.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147fnsIAA',
    LastCloseDateChangedHistoryId: '008Dn0000147fnxIAA',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQAUwIAP',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: true,
    Name: 'Palantir Technologies',
    Description: 'TEST',
    StageName: 'Negotiation/Review',
    Amount: 2939200,
    Probability: 90,
    ExpectedRevenue: 2645280,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-04-05',
    Type: null,
    NextStep: 'send contract O',
    LeadSource: 'Phone Inquiry',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Forecast',
    ForecastCategoryName: 'Commit',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2024-02-09T15:21:46.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 2,
    FiscalYear: 2024,
    Fiscal: '2024 2',
    ContactId: null,
    LastViewedDate: '2024-03-13T18:33:42.000+0000',
    LastReferencedDate: '2024-03-13T18:33:42.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147fm1IAA',
    LastCloseDateChangedHistoryId: '008Dn0000147i3KIAQ',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQAiVIAX',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Brex',
    Description: 'TEST',
    StageName: 'Closed Lost',
    Amount: 560000,
    Probability: 0,
    ExpectedRevenue: 0,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-23',
    Type: null,
    NextStep: null,
    LeadSource: 'Partner Referral',
    IsClosed: true,
    IsWon: false,
    ForecastCategory: 'Omitted',
    ForecastCategoryName: 'Omitted',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn0000072NyuIAE',
    CreatedDate: '2024-02-12T18:34:22.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:45.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:45.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147Vy3IAE',
    LastCloseDateChangedHistoryId: '008Dn0000147VfaIAE',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQBx0IAH',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Alphabet',
    Description: 'TEST',
    StageName: 'Closed Lost',
    Amount: 90000000,
    Probability: 0,
    ExpectedRevenue: 0,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-13',
    Type: null,
    NextStep: null,
    LeadSource: 'Web',
    IsClosed: true,
    IsWon: false,
    ForecastCategory: 'Omitted',
    ForecastCategoryName: 'Omitted',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2024-02-13T15:43:37.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: null,
    LastReferencedDate: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147eNoIAI',
    LastCloseDateChangedHistoryId: '008Dn0000147VpaIAE',
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
  {
    Id: '006Dn00000EQCfUIAX',
    IsDeleted: false,
    AccountId: null,
    RecordTypeId: '012Dn0000008EAWIA2',
    IsPrivate: false,
    Name: 'Stripe',
    Description: 'TEST',
    StageName: 'Needs Analysis',
    Amount: null,
    Probability: 20,
    ExpectedRevenue: null,
    TotalOpportunityQuantity: null,
    CloseDate: '2024-02-21',
    Type: null,
    NextStep: null,
    LeadSource: 'Web',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dn000002oqXoIAI',
    CreatedDate: '2024-02-21T05:03:51.000+0000',
    CreatedById: '005Dn000002oqXoIAI',
    LastModifiedDate: '2024-03-25T05:00:44.000+0000',
    LastModifiedById: '005Dn000002oqXoIAI',
    SystemModstamp: '2024-03-25T05:00:44.000+0000',
    LastActivityDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2024,
    Fiscal: '2024 1',
    ContactId: null,
    LastViewedDate: '2024-03-13T09:52:46.000+0000',
    LastReferencedDate: '2024-03-13T09:52:46.000+0000',
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: '008Dn0000147fbNIAQ',
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: null,
    Contact_End_Date__c: null,
    PrimaryLenderr__c: null,
    BDR_Credit__c: false,
    Marketing_Meeting_Completed__c: false,
    BDR_Credit_Date__c: null,
    Test_Boolean__c: false,
    Last_Meeting__c: null,
    TestBooleanField__c: false,
    AccountRating__c: null,
    Account_Employees__c: null,
    SAO_Timestamp__c: null,
    Opportunity_BDR_Owner__c: null,
    Contact__c: null,
    AirtableId__c: null,
    LastContact__c: null,
    Priority__c: null,
    Closed_Lost_Date__c: null,
    Closed_Won_Date__c: null,
    Id_Decision_Makers_Date__c: null,
    Needs_Analysis_Date__c: null,
    Negotiation_Review_Date__c: null,
    Perception_Analysis_Date__c: null,
    Proposal_Price_Quote_Date__c: null,
    Prospecting_Date__c: null,
    Qualification_Date__c: null,
    Value_Proposition_Date__c: null,
    Closed_Date__c: null,
    Number_of_Days_to_Close__c: null,
  },
]
